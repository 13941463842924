@import "src/theme/exports";

.dropdown-item {
  --border-width: 0;
  --background: var(--secondary-175);

  padding: 0;
  border: 1px solid var(--secondary-200);
  cursor: pointer;

  @include background-hover-desktop(var(--secondary-200));

  &:not(:last-child) {
    border-bottom: 0;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  img {
    width: 20px;
  }
}

.item-disabled {
  opacity: 0.3;
  color: var(--color);
  pointer-events: none;
}

.item-selected {
  --background: var(--white);

  padding: 0;
  color: var(--dark-grey);
  cursor: pointer;
  font-weight: var(--gotham-medium);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  .u-h6 {
    display: inline-block;
    width: 100%;
  }

  i {
    color: var(--green);
    font-size: 22px;
    transition: transform ease-in 0.2s;
  }

  .reverse-arrow {
    transform: rotate(180deg);
  }
}

.dropdown {
  position: absolute;
  z-index: 10;
  width: 100%;
  max-height: 425px;
  padding: 0;
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch !important;
}
